import React from "react";
import { graphql } from "gatsby";
import { SanitySlice, SEO } from "~components";

interface IProps {
  data: {
    sanityPage?: {
      title: string;
      slug: {
        current: string;
      };
      pagebuilder: {
        slices: any[];
      };
    };
    sanityBuildPage?: {
      title: string;
      slug: {
        current: string;
      };
      websitePagebuilder: {
        slices: any[];
      };
    };
    sanityWebsitePage?: {
      title: string;
      slug: {
        current: string;
      };
      websitePagebuilder: {
        slices: any[];
      };
    };
    siteBuildMetadata: {
      buildTime: string;
    };
  };
}

const WebsitePage = ({ data: staticData }: IProps) => {
  // const { siteBuildMetadata } = staticData;
  const pageData = staticData?.sanityBuildPage;

  console.log(staticData, "staticData");

  return (
    <>
      <SEO pageTitle={pageData?.title} />

      {pageData?.websitePagebuilder?.slices.map((slice) => (
        <SanitySlice
          key={slice._key}
          data={{
            ...slice,
            lastUpdated: staticData?.siteBuildMetadata?.buildTime
          }}
        />
      ))}
    </>
  );
};

export default WebsitePage;

export const query = graphql`
  query WebsitePage($id: String!) {
    siteBuildMetadata {
      buildTime
    }
    sanityPage(id: { eq: $id }) {
      title
      slug {
        current
      }

      pagebuilder {
        ...PagebuilderFragment
      }
    }
    sanityBuildPage(id: { eq: $id }) {
      title
      slug {
        current
      }

      websitePagebuilder {
        ...WebsitePagebuilderFragment
      }
    }
  }
`;
